<template>
    <v-row justify="center" class="py-5" align="center" style="height: 100%">
        <v-col style="text-align: center" cols="12" sm="6">
            <router-link :to="{ name: 'brand', params: { alias: this.$route.params.alias }, query: { email: this.$route.query.email } }">
            <BrandImage :alias="brand.alias" intent="logotype" mode="light" etag="0" :height="56" :max-height="56" contain style="margin-left: auto; margin-right: auto;" class="mb-6"/>
            </router-link>
            <v-progress-circular :color="primaryColor" :size="40" indeterminate v-if="!isAuthenticatedReady"/>
            <BrandCard v-if="isAuthenticatedReady && isAuthenticated">
                <template #title>
                    Subscribe
                </template>
                <!-- <template #menu>
                    <v-menu offset-y open-on-click open-on-hover bottom left close-delay="100">
                        <template #activator="{ on, attrs }">
                            <v-btn icon :color="primaryTextColor" v-bind="attrs" v-on="on" >
                                <font-awesome-icon :icon="['far', 'ellipsis-v']" fixed-width style="font-size: 16px;"/>
                            </v-btn>
                        </template>
                        <v-list class="ma-0 pa-0">
                            <v-list-item :to="{ name: 'profile' }">
                                <v-list-item-icon>
                                    <font-awesome-icon :icon="['fas', 'user']" class="grey--text text--darken-2" fixed-width/>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Profile
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item :to="{ name: 'user-account-list' }">
                                <v-list-item-icon>
                                    <font-awesome-icon :icon="['fas', 'folder']" class="grey--text text--darken-2" fixed-width/>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Accounts
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item :to="{ name: 'dashboard' }">
                                <v-list-item-icon>
                                    <font-awesome-icon :icon="['fas', 'chart-line']" class="grey--text text--darken-2" fixed-width/>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Dashboard
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item :to="{ name: 'service-admin' }" v-if="isPermitServiceAdmin">
                                <v-list-item-icon>
                                    <font-awesome-icon :icon="['fas', 'id-badge']" class="grey--text text--darken-2" fixed-width/>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Service Admin
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider/>
                            <v-list-item @click="onLogout">
                                <v-list-item-icon>
                                    <font-awesome-icon :icon="['fas', 'shield-alt']" class="grey--text text--darken-2" fixed-width/>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Logout
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template> -->
                <v-card-text class="text-start">
                <p class="mb-0">Select the communication topics you want to receive by email:</p>
                </v-card-text>
                <SelectEmailTopics :brandprofile="this.$route.params.alias" :email="email"/>
            </BrandCard>
        </v-col>
    </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import BrandCard from '@/components/BrandCard.vue';
import BrandImage from '@/components/BrandImage.vue';
import SelectEmailTopics from '@/components/SelectEmailTopics.vue';

export default {
    components: {
        BrandCard,
        BrandImage,
        SelectEmailTopics,
    },
    data: () => ({
        email: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            brand: (state) => state.brand,
        }),
        ...mapGetters({
            isLoading: 'isLoading',
            isAuthenticatedReady: 'isAuthenticatedReady',
            brandName: 'brandName',
            primaryColor: 'primaryColor',
            primaryTextColor: 'primaryTextColor',
            accentColor: 'accentColor',
            cardTitleBarTextStyle: 'cardTitleBarTextStyle',
            cardTitleBarStyle: 'cardTitleBarStyle',
            primaryButtonStyle: 'primaryButtonStyle',
            primaryIconStyle: 'primaryIconStyle',
        }),
        mainWebsiteURL() {
            return process.env.VUE_APP_MAIN_WEBSITE_URL ?? 'https://emplus.io';
        },
        isAuthenticated() {
            return this.email && Array.isArray(this.session?.email) && this.session.email?.map((item) => item.toLowerCase()).includes(this.email.toLowerCase());
        },
    },
    watch: {
        isAuthenticated(newValue) {
            if (!newValue) {
                this.$router.replace({ name: 'brand-authenticate', params: { alias: this.$route.params.alias }, query: { email: this.$route.query.email, action: 'subscribe' } });
            }
        },
    },
    mounted() {
        // allow apps to construct a URL like http://contact.emplus.test/brand/example-brand/unsubscribe?email=sparky@example.com so we auto-fill the email input
        if (this.$route.query.email) {
            this.email = this.$route.query.email;
        } else {
            this.$router.replace({ name: 'brand-authenticate', params: { alias: this.$route.params.alias }, query: { action: 'subscribe' } });
        }
    },
};
</script>
