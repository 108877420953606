<template>
    <v-form @submit.prevent="edit" class="mx-4 pb-4">
        <v-progress-circular :color="primaryColor" :size="40" indeterminate v-if="!isViewReady"/>
        <template v-if="isViewReady">
            <v-checkbox v-for="topic in emailContact.topics" :key="topic.id" :label="topic.label" :value="topic.id" v-model="selected" @change="editPermit(topic)" :color="primaryColor" dense>
            </v-checkbox>
        </template>
    </v-form>
</template>

<style>
/*
Decrease vertical spacing between the checkboxes by hiding
the messages area under each checkbox.
*/
.v-input--checkbox .v-messages {
    display: none;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
    props: ['brandprofile', 'email'],

    data: () => ({
        emailContact: null, // { brand, email, enabled, disabled, topics }
        selected: [], // selected topics
    }),

    computed: {
        ...mapState({
            session: (state) => state.session,
            brand: (state) => state.brand,
            focus: (state) => state.focus,
        }),
        ...mapGetters({
            isLoading: 'isLoading',
            isAuthenticatedReady: 'isAuthenticatedReady',
            brandName: 'brandName',
            primaryColor: 'primaryColor',
            primaryTextColor: 'primaryTextColor',
            accentColor: 'accentColor',
            cardTitleBarTextStyle: 'cardTitleBarTextStyle',
            cardTitleBarStyle: 'cardTitleBarStyle',
            primaryButtonStyle: 'primaryButtonStyle',
            primaryIconStyle: 'primaryIconStyle',
        }),
        isAuthenticated() {
            return this.email && Array.isArray(this.session?.email) && this.session.email?.map((item) => item.toLowerCase()).includes(this.email.toLowerCase());
        },
        isViewReady() {
            return this.isAuthenticated && this.emailContact !== null;
        },
    },

    watch: {
        isAuthenticated(newValue) {
            if (newValue) {
                this.loadEmailContact();
            }
        },
        focus() {
            if (this.isAuthenticated) {
                this.loadEmailContact();
            }
        },
    },

    methods: {
        async loadEmailContact() {
            try {
                this.$store.commit('loading', { loadEmailContact: true });
                const response = await this.$client.main().emailContact.get({ brandprofile_alias: this.brandprofile, email: this.email });
                if (response) {
                    this.emailContact = response;
                    if (Array.isArray(response.enabled)) {
                        this.selected = response.enabled;
                    }
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load communication preferences' });
                }
            } catch (err) {
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load communication preferences' });
            } finally {
                this.$store.commit('loading', { loadEmailContact: false });
            }
        },
        async editPermit(item) {
            try {
                this.$store.commit('loading', { editPermit: true });
                const permit = this.selected.includes(item.id);
                const response = await this.$client.main().emailContact.edit({
                    brandprofile_alias: this.brandprofile,
                    email: this.email,
                    topic_id: item.id,
                    permit,
                });
                if (response) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'Saved' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to save changes' });
                }
            } catch (err) {
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to save changes' });
            } finally {
                this.$store.commit('loading', { editPermit: false });
            }
        },
    },

    mounted() {
        if (this.brandprofile && this.email && this.isAuthenticated) {
            this.loadEmailContact();
        }
    },

};
</script>
